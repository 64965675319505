:root {
    --input-border: #8b8a8b;
    --input-focus-h: 245;
    --input-focus-s: 100%;
    --input-focus-l: 42%;
  }

.previewbox{
    grid-area: previewbox;
    padding: 30px;
}  

.formularioAdd{
    display: grid;
    grid-template-areas: 
    "previewbox cuadros";
    grid-template-columns: 1fr 1fr;
}

.NavbarPanel{
    background-color: white;
    padding: 40px;
    text-align: center;

    a{
        font-family: $barlow;
        text-align: justify;
        color: #d3079a;
        padding: 100px;
        display: inline;
        
    }

    a:active{
        font-family: $barlow;
        text-align: justify;
        color: black;
        padding: 100px;
        display: inline;
        
    }

    h2{
        font-family: $barlow;
        text-align: center;
        color: white;
        
    }
}




.cuadros{
    grid-area: cuadros;
    display: grid;
    place-items: left;
    background-color: #E2E2E2;
    
    //display: block;
    //text-align: center;
    //padding-left: 40px;
    //padding-right: 20px;

    label{
        font-family: $barlow;
        padding: 20px;
    }

    li{
        padding: 20px;
    }

}

.inputbox{
    background-color: white;
    border: 2px solid var(--input-border);
    width: 100%;
    //padding: 0px;
}


.botoncito{
    margin: auto;
    width: 200px;
    height: 50px;
    border-radius: 10px;
    color: white;
    text-align: center;
    background-color: black;
    padding: 10px;
}
.botonenvio{
    //display: flex;
    //justify-content: center;
    //align-items: center;
    margin: auto;
    width: 200px;
    height: 50px;
    color: black;
    border-radius: 10px;
    background-color: none;
    text-align: center;
    cursor: pointer;
    padding: 20px;
}



.card-body{
    padding:30px;
}
