*{
    margin: 0;
    padding: 0;
  }
  
  //fondo  del subir imagen
  .page{
    height: 81vh;
    background-color: #E2E2E2;
    display: flex;
    align-items: center;
    justify-content: center;
  
  }

.heading{
    text-align: center;
    font-family: Arial, Helvetica, sans-serif;
    margin-top: 1rem;
    font-weight: bold;
  
  }
  .img-holder{
    margin: auto;
    width: 400px;
    height: 400px;
    border: 3px black solid;
    border-radius: 5px;
    margin-top: 1rem;
  }
  .img{
    width: 390px;
    height: 390px;
    object-fit: cover;
  }
  #input[type="file"] {
      display: none;
  }
  .label{
    width: 100%;
    margin-top: 1rem;
    display: flex;
    justify-content: center;
  }
  .image-upload{
    margin: auto;
    width: 200px;
      height: 50px;
    color: white;
    border-radius: 10px;
    background-color: black;
    text-align: center;
    cursor: pointer;
  }